import TYPES from '@/types';
import { v4 as uuid } from 'uuid';
import { requiredRule, requiredRuleByKey } from '@/vue-app/utils/form-rules';

// Application
import {
  SearchJobTypesQuery,
} from '@/modules/my-investment/catalogs/kuspit/job-type/application/queries';
import {
  SearchActivitiesQuery,
} from '@/modules/my-investment/catalogs/kuspit/activity/application/queries';
import {
  SearchBusinessActivitiesQuery,
} from '@/modules/my-investment/catalogs/kuspit/business-activity/application/queries';
import SearchAgreementsAsyncQuery
  from '@/modules/my-investment/agreements/application/queries/search-agreements-async-query';
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  CustomerAgreementEntity,
} from '@/modules/on-boarding/customer-agreements/domain/entities/customer-agreement-entity';
import {
  AgreementEntity,
} from '@/modules/my-investment/agreements/domain/entities/agreement-entity';
import {
  ActivityEntity,
} from '@/modules/my-investment/catalogs/kuspit/activity/domain/entities/activity-entity';
import {
  JobTypeEntity,
} from '@/modules/my-investment/catalogs/kuspit/job-type/domain/entities/job-type-entity';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ContractSavingsProfilingWorkKuspitViewModel {
  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_JOB_TYPE_QUERY)
  private readonly searchJobTypesQuery!: SearchJobTypesQuery;

  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_ACTIVITY_QUERY)
  private readonly searchActivitiesQuery!: SearchActivitiesQuery;

  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_BUSINESS_ACTIVITY_QUERY)
  private readonly searchBusinessActivitiesQuery!: SearchBusinessActivitiesQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_AGREEMENTS_ASYNC_QUERY)
  private readonly searchAgreementsAsyncQuery!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly getPersonQuery!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.DATETIME_VALUE)
  private datetime_value!: DatetimeValue;

  i18n_namespace = 'components.contract-savings.profiling.work.kuspit';

  small_screen: null | boolean = null;

  form_validity = false;

  acceptance = {
    real_provider: false,
    resource_owner: false,
    not_pep: false,
  };

  investment_provider_name = 'kuspit';

  exists_step = false;

  step_name = 'on_boarding_kuspit_activity';

  investment_provider_id = '';

  is_loading = true;

  user_agreements: Array<CustomerAgreementEntity> = [];

  activities: Array<ActivityEntity> = [];

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {},
  };

  inputs = {
    have_business_activities: '',
    occupation: '',
    job_type: {
      id: '',
      descripcion: '',
    },
    activity: {
      id: '',
      descripcion: '',
    },
  };

  payload = {
    has_business_activity: {
      id: '',
      description: '',
    },
    occupation: '',
    job_type: {
      id: '',
      description: '',
    },
    activity: {
      id: '',
      description: '',
    },
    agreements: this.user_agreements,
  };

  show = {
    politically_exposed_person: false,
    actual_supplier_of_assets: false,
    actual_owner_of_resources: false,
  };

  rules = {
    occupation: [requiredRule],
    have_business_activities: [requiredRule],
    pep: [requiredRule],
    select: [(value: any) => requiredRuleByKey(value, 'id')],
  };

  agreements_list: Array<AgreementEntity> = [];

  job_types: Array<JobTypeEntity> = [];

  get canContinue(): boolean {
    return (
      !Object.values(this.acceptance)
        .some((field) => !field)
      && this.form_validity
    );
  }

  get agreements() {
    return this.searchAgreementsAsyncQuery.execute();
  }

  get userName() {
    return this.getPersonQuery.execute().name;
  }

  searchActivities = async () => {
    if (this.inputs.job_type !== null) {
      const activities = await this.searchActivitiesQuery.execute(this.inputs.job_type.id);
      this.activities = activities.map((activity) => (
        { ...activity, descripcion: activity.descripcion.replace('&Ntilde;', 'Ñ') }
      ));
    }

    return [];
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      this.setInputsDataFromStep();
    }
  };

  setInputsDataFromStep = () => {
    this.inputs.have_business_activities = this.on_boarding_step.payload.has_business_activity
      .description === 'Sí' ? 'yes' : 'no';
    this.inputs.occupation = this.on_boarding_step.payload.occupation;
    this.inputs.job_type = {
      id: this.on_boarding_step.payload.job_type.id,
      descripcion: this.on_boarding_step.payload.job_type.description,
    };
    this.inputs.activity = {
      id: this.on_boarding_step.payload.activity.id,
      descripcion: this.on_boarding_step.payload.activity.description,
    };
  }

  setIfBusinessActivity = async (have_business_activity: string) => {
    const description = have_business_activity === 'yes' ? 'Sí' : 'No';
    const business_activities = await this.searchBusinessActivitiesQuery.execute();
    const business_activity = business_activities.find(
      (item) => item.descripcion === description,
    );
    if (business_activity) {
      this.payload.has_business_activity = {
        id: business_activity.id,
        description: business_activity.descripcion,
      };
    }
  };

  createAgreement(agreement_name: string, agreements_list: Array<AgreementEntity>) {
    const agreement = agreements_list.find(
      (item) => item.name === agreement_name,
    );
    if (agreement) {
      this.user_agreements.push({
        id: uuid(),
        agreement_type_id: agreement.id,
        accepted_on: null,
        description: agreement.description,
        was_accepted: false,
      });
    }
  }

  setUserAgreements = async () => {
    this.agreements_list = await this.searchAgreementsAsyncQuery.execute();
    this.createAgreement('not_politically_exposed_person_kuspit', this.agreements_list);
    this.createAgreement('resource_provider_kuspit', this.agreements_list);
    this.createAgreement('resource_owner_kuspit', this.agreements_list);
  };

  handleAgreementChange(agreement_name: string, confirmed: boolean) {
    const agreement = this.agreements_list.find(
      (item) => item.name === agreement_name,
    );

    if (agreement) {
      const user_agreement_index = this.user_agreements.findIndex(
        (item) => item.agreement_type_id === agreement.id,
      );

      if (user_agreement_index >= 0) {
        // eslint-disable-next-line max-len
        this.user_agreements[user_agreement_index].accepted_on = confirmed ? this.datetime_value.create() : null;
        this.user_agreements[user_agreement_index].was_accepted = confirmed;
      }
    }
  }

  selectNotPpe = (event: Event) => {
    event.stopPropagation();
    this.show.politically_exposed_person = true;
    this.acceptance.not_pep = true;
  }

  selectActualSupplierOfAssets = (event: Event) => {
    event.stopPropagation();
    this.show.actual_supplier_of_assets = true;
    this.acceptance.real_provider = true;
  }

  selectResourceOwner = (event: Event) => {
    event.stopPropagation();
    this.show.actual_owner_of_resources = true;
    this.acceptance.resource_owner = true;
  }

  async saveStep() {
    try {
      this.payload.activity = {
        id: this.inputs.activity.id,
        description: this.inputs.activity.descripcion,
      };
      this.payload.job_type = {
        id: this.inputs.job_type.id,
        description: this.inputs.job_type.descripcion,
      };
      this.payload.occupation = this.inputs.occupation;
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload = this.payload;
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(
        'Ha ocurrido un error al guardar tu información, inténtalo nuevamente',
      );
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    this.job_types = await this.searchJobTypesQuery.execute();

    const investment_providers = await this.searchInvestmentProvidersQuery.execute();
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
    await this.verifyStep(steps);

    await this.setIfBusinessActivity(
      this.inputs.have_business_activities,
    );
    await this.setUserAgreements();

    this.is_loading = false;
  }
}
