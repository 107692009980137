

































































import { Vue, Component, PropSync } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';

@Component({ name: 'ActualOwnerOfResources' })
export default class ActualOwnerOfResources extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  i18n_namespace = 'components.contract-savings.additional-information.actual-owner-of-resources';

  small_screen: null | boolean = null;

  mounted() {
    this.small_screen = getScreenSizeVariant() === 'small';
  }
}

